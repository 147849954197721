import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { EmailListItem, AssociatedEmail } from "./types";

// Customizable Area End

// Customizable Area Start
export type NavObject = TypeNav
export interface TypeNav {
  addListener: Function
  goBack: Function,
  getParam:Function,
  navigate:Function
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: NavObject;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  loader: boolean;
  emailListTitle: string;
  emailListId: number;
  associatedEmailsList: AssociatedEmail[];
  islistEmpty: boolean;
  emailText: string;
  nameText: string;
  searchText: string;
  searchResultMsg: boolean;
  editMode: boolean;
  email: string;
  fullName: string;
  emailId: number | null,
  pageNum: number,
  totalPages: number,
  pageSize: number,
  groupList: any;
  currentPage: number
  token: any;
  profile: any;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class EmailListDataListingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addEmailDataAPICallId = ""
  getEmailsAPICallId = ""
  updateEmailAPICallId = ""
  deleteEmailAPICallId = ""
  searchEmailApiCallId = ""
  getCampaignCallId = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.getAssociatedEmails = this.getAssociatedEmails.bind(this);
    this.searchResponse = this.searchResponse.bind(this);
    this.deleteEmailresponse = this.deleteEmailresponse.bind(this);
    this.onEmailSearch = this.onEmailSearch.bind(this);
    this.updateEmailResponse = this.updateEmailResponse.bind(this);
    this.deleteEmailData = this.deleteEmailData.bind(this);
    this.apiFetchCall = this.apiFetchCall.bind(this);
    this.updateEmailData = this.updateEmailData.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.getAssociatedEmails = this.getAssociatedEmails.bind(this);
    this.calcTotalPages = this.calcTotalPages.bind(this);
    this.clearSearchRedirect = this.clearSearchRedirect.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.updateEmailData = this.updateEmailData.bind(this);
    this.getAssociatedEmails = this.getAssociatedEmails.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.onDelete = this.onDelete.bind(this)
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIRequestMethodMessage),
      // Customizable Area End
    ];
    this.state = {
      
      // Customizable Area Start
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      loader: false,
      emailListTitle: '',
      emailListId: 0,
      associatedEmailsList: [],
      islistEmpty: false,
      emailText: '',
      nameText: '',
      searchText: '',
      searchResultMsg: false,
      editMode: false,
      email: '',
      fullName: '',
      emailId: null,
      pageNum: 1,
      totalPages: 1,
      pageSize: 10,
      currentPage: 1,
      groupList: [],
      token: "",
      profile: undefined
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (message.id === getName(MessageEnum.RestAPIResponceMessage) && getName(MessageEnum.RestAPIResponceMessage) === message.id  && responseJson) {
      this.setState({ loader: false })
           if (apiRequestCallId === this.updateEmailAPICallId) {
            this.updateEmailResponse()
          } else if (apiRequestCallId === this.deleteEmailAPICallId) {
            this.deleteEmailresponse(responseJson)
          } else if (apiRequestCallId === this.searchEmailApiCallId) {
            this.searchResponse(responseJson)
          }  if (apiRequestCallId === this.getCampaignCallId) {
            this.setState({groupList: responseJson.data})
          } 
        }  
    // Customizable Area End
  }

  onChangeSearch = (event: {target: {value: string}}) => {
    this.setState({ searchText: event?.target?.value })
  }

  // Customizable Area Start
  async componentDidMount() {
      const emailListId = await getStorageData('emailListId');
      const emailListTitle = await getStorageData('emailListTitle');
      const emailId = emailListId ? parseInt(emailListId) : 0;
      this.setState({
        emailListId: emailId,
        emailListTitle: emailListTitle || '',
      });
      this.getAssociatedEmails(emailId);

    getStorageData("token").then((token) => {
      this.onGettingCampaign(token);
      this.setState({token: token})
    });
    getStorageData("profile").then((profile) => {
      this.setState({profile: profile})
    });
  }

  onDelete = (item: number|string|undefined) => {
    this.deleteEmailData(item);
  }

  calcTotalPages = () => {
    let pageSize= this.state.pageSize
    let emailListTitle =  this.state.associatedEmailsList.length
    let totalPages = (emailListTitle/ pageSize);
    this.setState({ totalPages });
  }

  clearSearchRedirect = () => {
    this.setState({ searchResultMsg: false}, () => {
      this.getAssociatedEmails(this.state.emailListId);
    });
  }

  handleChangeEmail = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    this.state.associatedEmailsList[index].email = event?.target?.value
    this.setState({ associatedEmailsList: this.state.associatedEmailsList });
  }

  handleNameChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    this.state.associatedEmailsList[index].full_name = event?.target?.value
    this.setState({ associatedEmailsList: this.state.associatedEmailsList });
  }

  getAssociatedEmails = async (accountId: number) => {
    this.setState({loader: true})
    this.getEmailsAPICallId = await this.apiFetchCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.emailListCommonApiEndPoint}/${accountId}`
    });
  };

  updateEmailData = async (index: number) => {
    let httpBody = {
      user_id: this.state.associatedEmailsList[index].id,
      email: this.state.associatedEmailsList[index].email,
      full_name: this.state.associatedEmailsList[index].full_name
    }
    
    this.updateEmailAPICallId = await this.apiFetchCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.putApiMethodType,
      endPoint: `${configJSON.emailListCommonApiEndPoint}${this.state.emailListId}/${configJSON.updateEmailApiEndPoint2}`,
      httpBody: httpBody
    });
  }

  deleteEmailData = async (accountId: number|string|undefined) => {
    let httpBody = {
      account_id: accountId
    }
    this.deleteEmailAPICallId = await this.apiFetchCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.deleteApiMethodType,
      endPoint: `${configJSON.emailListCommonApiEndPoint}${this.state.emailListId}${configJSON.deleteEmailDataApiEndPoint2}`,
      httpBody: httpBody
    });
  }

  onEmailSearch = async () => {
    this.searchEmailApiCallId = await this.apiFetchCall({
      contentType: configJSON.validationApiContentType,
      method: configJSON.validationApiMethodType,
      endPoint: `${configJSON.emailListCommonApiEndPoint}${this.state.emailListId}${configJSON.searchAssociatedEmailfromListApiEndPoint}${this.state.searchText}`,
    });
  }
  
  apiFetchCall = async (data:{[key: string]: string |number |object|[] | boolean}) => {
    const { contentType, method, endPoint, httpBody } = data
    const headers = {
      'Content-Type': contentType,
      token: configJSON.token
    }
    const requestDataMessage = new Message(getName(MessageEnum.RestAPIRequestMessage))
    requestDataMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(headers))
    requestDataMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint)
    requestDataMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method)
    httpBody ? requestDataMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),JSON.stringify(httpBody))
      : requestDataMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),httpBody);
    runEngine.sendMessage(requestDataMessage.id, requestDataMessage);
    return requestDataMessage.messageId;
  }

  updateEmailResponse = () => {
    this.setState({
      loader: false,
      associatedEmailsList: this.state.associatedEmailsList
    }, () => {
      alert("Data Updated Successfully");
    if(!this.state.searchResultMsg){
      this.getAssociatedEmails(this.state.emailListId)
    } else {
      this.onEmailSearch()
    }
    });
  }

  deleteEmailresponse = (response: {message: string}) => {
    const isDelete = window.confirm("Are you sure you want to delete this email list?");
    if (isDelete) {
      alert(response.message)
      this.getAssociatedEmails(this.state.emailListId)
    }
    else {
      alert("Email Deletion Canceled");
    }
  }

  searchResponse = (response: EmailListItem) => {   
    if(response.message === "Records Not Found") {
      this.setState({ associatedEmailsList: [], searchResultMsg: false })
    } else {
      this.setState({ associatedEmailsList: response.data, searchResultMsg: true})
    }
  }

  handlePreviousPage = () => {
    this.setState(prevState => ({
      pageNum: Math.max(prevState.pageNum - 1, 1),
    }));
  }

  handleNextPage = () => {
    this.setState(prevState => ({pageNum: prevState.pageNum + 1 }), () => {
      this.calcTotalPages();
      this.deleteEmailData(0);
      this.getAssociatedEmails(this.state.emailListId);
    });
  }

  onGettingCampaign = (token: string) => {
    const header = {
      "Content-Type": "application/json",
      token: token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCampaignCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_projecttemplates/campaigns"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
}

  // Customizable Area End
}
