import React from "react";
import {
  Box,
  Button,
  Typography,
  // Customizable Area Start
  IconButton,
  Pagination,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  // Customizable Area End
} from "@mui/material";
import EmailListDataListingController, {
  Props,
  configJSON,
} from "./EmailListDataListingController.web";
import { searchIconLogo } from "./assets";
import Layout from "../../../components/src/Layout.web";
import { ic_back, ic_plus } from "../../../blocks/accountgroups/src/assets";
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';

export default class EmailListDataListing extends EmailListDataListingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  SearchTextField = () => {
    return (
      <input
        type="text"
        value={this.state.searchText}
        onChange={this.onChangeSearch.bind(this)}
        style={{ width: "100%", border: "none", borderRadius: "60px" }}
        data-test-id="searchTxt"
      />
    );
  };

  SearchButton = () => {
    return (
      <Button onClick={this.onEmailSearch.bind(this)} data-test-id="searchIcon">
        <img
          src={searchIconLogo}
          width="40px"
          height="35px"
          style={{ borderRadius: "60px" }}
          data-test-id="searchIconImg"
        />
      </Button>
    );
  };

  getResultsSummary = () => {
    const itemsPerPage = 12;
    const totalItems = this.state.groupList.length ?? 0; // Total results
    const startIndex = (this.state.currentPage - 1) * itemsPerPage + 1; // Start index of current page
    const endIndex = Math.min(this.state.currentPage * itemsPerPage, totalItems); // End index of current page
    return `${totalItems ? startIndex : 0} - ${endIndex} of ${totalItems} ${totalItems > 1 ? "results" : "result"}`;
  };

  render() {
    
    return (
      <Layout navigation={this.props.navigation} currentRoute='Manage Users'>
      <StyledContainer>
        {/* Header Section */}
        <Box className="header-container">
          <img src={ic_back.default} style={{width: "28px", height: "28px", position: "absolute", top: "30px"}} />
          {/* Title and Description */}
          <Box style={{display: "flex", flexDirection: "column", marginLeft: "80px"}}>
          <Typography variant="h5" fontWeight="bold" style={{
            color: "#00184C",
            fontSize: "24px",
            
          }}>
            Welcome back, {this.state.profile?.attributes?.first_name}!
          </Typography>
          <Typography style={{
            color: "#00184C",
            fontSize: "16px",
            fontWeight: "400"
          }} variant="body2" color="textSecondary">
            Take a look at your organization!
          </Typography>
          </Box>
          {/* Actions (Add User, Filters, Search) */}
          <Box className="header-top">
            <Button
              style={{
                background: "linear-gradient(122.97deg, #A4DFFF -55.47%, #00184C 40.61%)",
                color: "#FFFFFF",
                borderRadius: "26px",
                padding: "10px 16px",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                textTransform: "capitalize",
                whiteSpace: "nowrap",
              }}
              variant="contained"
              color="primary"
            >
              <img src={ic_plus.default} style={{width: "24px", height: "24px"}} />
              New Campaign
            </Button>
            <IconButton className="filter-button" aria-label="filter">
            <FilterListIcon style={{ color: '#0F172A' }} />
            <span style={{color: "#75809B", fontSize: "14px", fontWeight: 700}}>Filters</span>
            </IconButton>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #E2E8F0',
                  borderRadius: '0px',
                  padding: '4px 8px',
                  width: '250px',
                  backgroundColor: "white",
                  height: "38px",
                  boxSizing: "border-box",
                  borderBottom: "1px solid #C4C4C6",
                }}
              >
              <SearchIcon sx={{ color: '#0F172A', marginRight: '8px' }} />
               <TextField
                  style={{height: "100%"}}
                  placeholder="Search"
                  size="small"
                  variant="standard"
                  InputProps={{
                    disableUnderline: true,
                    sx: { fontSize: '14px',
                       height: "100%",   
                      fontFamily: "Inter, sans-serif",
                      color: '#00184C', 
                      fontWeight: 700,
                  },
                  }}
                  value={this.state.searchText}
                  // onChange={this.handleSearchText}
                  fullWidth
                />
              </Box>
          </Box>
        </Box>

        {/* Table Section */}
        <Paper sx={{ display: "flex", flex: 1, padding: 0, boxShadow: "0px 2px 8px 0px #00000014", marginTop: "10px" }}>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow style={{overflow:"hidden"}}>
              <StyledTableCell>Campaign</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Bot</StyledTableCell>
              <StyledTableCell style={{textAlign: "center"}}>Applied on</StyledTableCell>
              <StyledTableCell style={{opacity: 0, borderRadius: 0}}>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.groupList.slice( (this.state.currentPage - 1) * 12, (this.state.currentPage - 1) * 12 + 12).map((group: any) => (
              <TableRow key={group.id} hover>
                <StyledTableCell style={{paddingLeft: "36px"}}>{`${group.attributes.campaign_name}`}</StyledTableCell>
                <StyledTableCell>
                  {group.attributes.status === "running" && <StatusButton>
                    {group.attributes.status}
                  </StatusButton>}
                  {group.attributes.status === "paused" && <PausedButton>
                    {group.attributes.status}
                  </PausedButton>}
                  {group.attributes.status === "completed" && <CompletedButton>
                    {group.attributes.status}
                  </CompletedButton>}
                </StyledTableCell>
                <StyledTableCell>{group.attributes.bot_id}</StyledTableCell>
                <StyledTableCell style={{
                    alignItems: "center",
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "100%",
                    paddingLeft: "36px"
                }}>
                
                </StyledTableCell>
                <StyledTableCell>
                  <div style={{display: "flex", gap: "15px", justifyContent: "center"}}>
                  <EditButton>Edit</EditButton>
                  <EditButton style={{ color:"white", backgroundColor: "#00184C"}}>Details</EditButton>
                  <EditButton style={{ backgroundColor:"#E4C9F3", color: "#00184C"}}>Pause</EditButton>
                  </div>
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
      </StyledContainer>
      <Stack
      direction="row"
      alignItems="flex-end"
      justifyContent="space-between"
      sx={{ marginTop: 2, height: "100%", marginBottom: "40px" }}
    >
      <div style={{width: "120px", height: "1px"}} />
      {/* Pagination */}
      <StyledPagination
        count={Math.ceil(this.state.groupList.length / 12)}
        page={this.state.currentPage}
        shape="rounded"
        siblingCount={0} 
        boundaryCount={1} 
        // onChange={this.handlePageChange}
      />

      {/* Results Summary */}
      <ResultsSummary>
        {this.getResultsSummary()}
      </ResultsSummary>
    </Stack>   
    </Layout>
    );
  }
}

const ResultsSummary = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: "14px",
}));

const StyledPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPagination-ul": {
    justifyContent: "center", // Align pagination to the left
  },
  "& .MuiPaginationItem-root": {
    color:"#00184C",
    fontWeight: 400,
    "&.Mui-selected": {
      color:"#00184C",
      borderRadius: "50%",
      backgroundColor: "transparent",
      fontWeight: 700,
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  backgroundColor: "white",
  color: "#22223E",
  textAlign: "start",
  borderBottom: "none",
  borderTop: "1px solid #E9E9EA",
  left: "20px"
}));

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",

  "*": { fontFamily: "Inter, sans-serif" },

  "& *": {
    boxSizing: "border-box",
    fontFamily: "Inter, sans-serif"
  },
  '.header-container': {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
    justifyContent: "space-between",
    backgroundColor: "#E9E9EA",
    marginTop: "20px",
  },
  '.header-top': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: "20px",
    flexDirection: "row",
    gap: "20px"
  },
  '.header-actions': {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  '.table-container': {
    maxHeight: 600,
    overflow: 'hidden',
  },
  '.filter-button': {
    padding: theme.spacing(1),
    width: "96px",
    height: "38px",
    borderBottom: "1px solid #C4C4C6",
    backgroundColor: "white",
    borderRadius: 0, 
    gap: "10px"
  },
}));


const StatusButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#D1FAE5",
  color:"#059669" ,
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
  "&:hover": {
    backgroundColor:"#c5f0d0",
  },
}));

const PausedButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#FEF3C7",
  color:"#D97706" ,
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
  "&:hover": {
    backgroundColor:"#c5f0d0",
  },
}));


const CompletedButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  backgroundColor: "#FEE2E2",
  color:"#DC2626" ,
  fontWeight: "bold",
  padding: "4px 12px",
  borderRadius: "40px",
  "&:hover": {
    backgroundColor:"#c5f0d0",
  },
}));

const EditButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  backgroundColor: "#fff",
  color: "#00184C",
  border: "1px solid #00184C",
  borderRadius: "8px",
  padding: "4px 8px",
  fontWeight: "bold",
  width: "75px",

  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));